<template>
    <div class="content-table-cadidate">
        <div class="table-cadidate">
            <a-table
                size="middle"
                rowKey="id"
                :columns="columns"
                :dataSource="cadidates"
                bordered
                :pagination="{
                    current: page,
                    total: candidateData.total,
                    pageSize,
                    pageSizeOptions: ['10', '50', '100'],
                    showSizeChanger: true,
                    size: 'large',
                }"
                @change="handleChangePage"
            >
                <span slot="index" slot-scope="text, record, index">
                    {{ index + 1 }}
                </span>
                <span slot="gender" slot-scope="text">{{
                    text == 'MALE' ? 'Nam' : text == 'FEMALE' ? 'Nữ' : 'Khác'
                }}</span>
                <span slot="idDateCreated" slot-scope="text, record">
                    {{ formatDate(record.idDateCreated) }}
                </span>
                <span slot="birthDay" slot-scope="text, record">
                    {{ formatDate(record.birthDay) }}
                </span>
                <span slot="toSalon" slot-scope="record">
                    <a-select
                        v-decorator="['toSalon']"
                        style="width: 100%"
                        show-search
                        :value="getSalonAssignee(record.salonAssignee)"
                        :filter-option="filterOption"
                        @change="(value) => handleChangeStatus(record, value)"
                    >
                        <a-select-option
                            v-for="salon in listSalon"
                            :key="salon.id"
                            :value="JSON.stringify(salon)"
                        >
                            {{ salon.name }}
                        </a-select-option>
                    </a-select>
                </span>
                <span slot="action" slot-scope="record">
                    <a class="ant-dropdown-link" @click="handleEdit(record.id)">
                        Sửa <a-icon type="edit" />
                    </a>
                </span>
            </a-table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { removeUnicodeChar } from '../../utils/CharacterUtils';
import { formatDate } from '../../utils/Date';

const columns = [
    {
        title: 'STT',
        key: 'index',
        align: 'center',
        scopedSlots: { customRender: 'index' },
    },
    {
        title: 'Vị trí',
        dataIndex: 'staffType.name',
        align: 'center',
    },
    {
        title: 'Họ tên',
        dataIndex: 'fullName',
        align: 'center',
    },
    {
        title: 'Giới tính',
        dataIndex: 'gender',
        align: 'center',
        scopedSlots: { customRender: 'gender' },
    },
    {
        title: 'CMND',
        dataIndex: 'idNumber',
        align: 'center',
    },
    {
        title: 'Ngày cấp',
        dataIndex: 'idDateCreated',
        align: 'center',
        scopedSlots: { customRender: 'idDateCreated' },
    },
    {
        title: 'Nơi cấp',
        dataIndex: 'idCity',
        align: 'center',
    },
    {
        title: 'Ngày sinh',
        dataIndex: 'birthDay',
        align: 'center',
        scopedSlots: { customRender: 'birthDay' },
    },
    {
        title: 'SĐT',
        dataIndex: 'phone',
        align: 'center',
    },
    {
        title: 'Địa chỉ',
        dataIndex: 'address',
        align: 'center',
    },
    {
        title: 'Khu vực',
        dataIndex: 'city.name',
        align: 'center',
    },
    {
        title: 'Nguồn TD',
        dataIndex: 'source.name',
        align: 'center',
    },
    {
        title: 'Chuyển đến salon',
        align: 'center',
        scopedSlots: { customRender: 'toSalon' },
        width: '250px',
    },
    {
        title: '',
        key: 'action',
        align: 'center',
        scopedSlots: { customRender: 'action' },
    },
];

export default {
    props: {
        page: Number,
        pageSize: Number,
        candidateData: Object,
    },

    data() {
        return {
            formatDate,
            columns,
            recordSelected: {},
        };
    },

    computed: {
        ...mapGetters('salon', {
            listSalon: 'salon',
        }),
        cadidates() {
            if (this.candidateData.candidates) {
                return this.candidateData.candidates;
            }
            return [];
        },
    },

    methods: {
        handleChangePage(pagination) {
            this.$emit('change-page', pagination);
        },

        handleEdit(id) {
            this.$router.push({
                path: `/candidate/edit/${id}`,
                query: { redirectUrl: '/candidate-online' },
            });
        },

        getSalonAssignee(id) {
            if (id == null) return null;
            return JSON.stringify(
                this.listSalon.find((salon) => salon.id === id)
            );
        },

        handleChangeStatus(record, value) {
            this.recordSelected = record;
            const salonSelected = JSON.parse(value);
            this.$confirm({
                title: `Xác nhận chuyển ứng viên`,
                content: (
                    <div>
                        Chuyển ứng viên
                        <span style={{ color: 'black', fontWeight: 'bold' }}>
                            {` ${this.recordSelected.fullName} `}
                        </span>
                        đến salon
                        <span style={{ color: 'black', fontWeight: 'bold' }}>
                            {` ${salonSelected.name} `}
                        </span>
                    </div>
                ),
                onOk: () => {
                    this.$emit('assignee-salon', {
                        id: this.recordSelected.id,
                        salonAssignee: salonSelected.id,
                    });
                },
                onCancel: () => {
                    this.recordSelected = {};
                },
                class: 'test',
                centered: true,
                maskClosable: true,
            });
        },

        filterOption(input, option) {
            return (
                removeUnicodeChar(
                    option.componentOptions.children[0].text.toLowerCase()
                ).indexOf(removeUnicodeChar(input.toLowerCase())) >= 0
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.content-table-cadidate {
    margin-top: 20px;

    .table-cadidate {
        margin-top: 15px;
    }
}
</style>
