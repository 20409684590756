var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-table-cadidate"},[_c('div',{staticClass:"table-cadidate"},[_c('a-table',{attrs:{"size":"middle","rowKey":"id","columns":_vm.columns,"dataSource":_vm.cadidates,"bordered":"","pagination":{
                current: _vm.page,
                total: _vm.candidateData.total,
                pageSize: _vm.pageSize,
                pageSizeOptions: ['10', '50', '100'],
                showSizeChanger: true,
                size: 'large',
            }},on:{"change":_vm.handleChangePage},scopedSlots:_vm._u([{key:"index",fn:function(text, record, index){return _c('span',{},[_vm._v("\n                "+_vm._s(index + 1)+"\n            ")])}},{key:"gender",fn:function(text){return _c('span',{},[_vm._v(_vm._s(text == 'MALE' ? 'Nam' : text == 'FEMALE' ? 'Nữ' : 'Khác'))])}},{key:"idDateCreated",fn:function(text, record){return _c('span',{},[_vm._v("\n                "+_vm._s(_vm.formatDate(record.idDateCreated))+"\n            ")])}},{key:"birthDay",fn:function(text, record){return _c('span',{},[_vm._v("\n                "+_vm._s(_vm.formatDate(record.birthDay))+"\n            ")])}},{key:"toSalon",fn:function(record){return _c('span',{},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['toSalon']),expression:"['toSalon']"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","value":_vm.getSalonAssignee(record.salonAssignee),"filter-option":_vm.filterOption},on:{"change":function (value) { return _vm.handleChangeStatus(record, value); }}},_vm._l((_vm.listSalon),function(salon){return _c('a-select-option',{key:salon.id,attrs:{"value":JSON.stringify(salon)}},[_vm._v("\n                        "+_vm._s(salon.name)+"\n                    ")])}),1)],1)}},{key:"action",fn:function(record){return _c('span',{},[_c('a',{staticClass:"ant-dropdown-link",on:{"click":function($event){return _vm.handleEdit(record.id)}}},[_vm._v("\n                    Sửa "),_c('a-icon',{attrs:{"type":"edit"}})],1)])}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }