<template>
    <a-layout-content class="CandidateList">
        <a-spin :spinning="isLoading">
            <a-breadcrumb style="margin: 16px 24px">
                <a-breadcrumb-item>Trang chủ</a-breadcrumb-item>
                <a-breadcrumb-item>Map ứng viên vào salon</a-breadcrumb-item>
            </a-breadcrumb>
            <div class="content">
                <form-filter @click-filter="handleFilter"></form-filter>
                <!-- @click-export="handleClickExport" -->
                <table-candidate
                    :page="page"
                    :pageSize="pageSize"
                    @change-page="handleChangePage"
                    :candidateData="candidate"
                    @assignee-salon="handleChangeAssigneeSalon"
                ></table-candidate>
            </div>
        </a-spin>
    </a-layout-content>
</template>

<script>
import { mapActions, mapState } from 'vuex';
// import ExcelHelper from '../../utils/ExcelUtils.js';
// import moment from 'moment';
import FormFilter from './FormFilter';
import TableCandidate from './TableCandidate';
import { RepositoryFactory } from '../../repository/RepositoryFactory';
const candidateRepository = RepositoryFactory.get('candidate');

// const HEADER_CSV = [
//     'STT',
//     'Vị trí',
//     'Họ tên',
//     'Giới tính',
//     'CMND',
//     'Ngày cấp',
//     'Nơi cấp',
//     'Ngày sinh',
//     'SĐT',
//     'Địa chỉ',
//     'Khu vực',
//     'Nguồn TD',
//     'Trạng thái',
//     'Lịch sử PV',
// ];

export default {
    name: 'CandidateOnlineList',

    components: { FormFilter, TableCandidate },

    data() {
        return {
            isLoading: false,
            page: 1,
            pageSize: 10,
        };
    },

    created() {
        this.fetchData();
    },

    computed: {
        ...mapState({
            candidate: (state) => state.candidate,
            round: (state) => state.round.data,
            user: (state) => state.authenticate.user,
        }),
    },

    methods: {
        ...mapActions('candidate', {
            fetchCandidate: 'fetch',
            // fetchCandidateExport: 'fetchDataExport',
        }),

        async fetchData(filter = {}) {
            this.isLoading = true;
            try {
                const params = {
                    ...filter,
                    round: {
                        firstStep: false,
                        lastStep: true,
                    },
                    status: {
                        passed: false,
                        pending: true,
                    },
                    page: this.page - 1,
                    pageSize: this.pageSize,
                };
                await this.fetchCandidate(params);
            } catch (error) {
                const { data } = error.response;
                // eslint-disable-next-line no-console
                console.log(data.message);
                this.$error({
                    title: 'Lấy thông tin thất bại!',
                    okText: 'OK',
                    centered: true,
                    onOk: () => {},
                });
            }
            this.isLoading = false;
        },

        // async fetchDataExport(params = {}) {
        //     this.isLoading = true;
        //     try {
        //         await this.fetchCandidateExport(params);
        //     } catch (error) {
        //         const { data } = error.response;
        //         // eslint-disable-next-line no-console
        //         console.log(data.message);
        //         this.$error({
        //             title: 'Export hồ sơ thất bại',
        //             okText: 'OK',
        //             centered: true,
        //             onOk: () => {},
        //         });
        //     }
        // },

        handleFilter(values) {
            this.page = 1;
            this.pageSize = 10;
            this.fetchData(values);
        },

        handleChangePage(pagination) {
            this.page = pagination.current;
            this.pageSize = pagination.pageSize;
            this.fetchData();
        },

        // formatDataExport() {
        //     const { candidateExport } = this.candidate;
        //     const result = [];
        //     candidateExport.forEach((candidate, index) => {
        //         const rowLine = [];
        //         rowLine.push(`${index + 1}`);
        //         rowLine.push(
        //             (candidate.staffType && candidate.staffType.name) || ''
        //         );
        //         rowLine.push(candidate.fullName || '');
        //         rowLine.push(candidate.gender == 'MALE' ? 'Nam' : 'Nữ');
        //         rowLine.push(candidate.idNumber || '');
        //         rowLine.push(candidate.idDateCreated || '');
        //         rowLine.push(candidate.idCity || '');
        //         rowLine.push(candidate.birthDay || '');
        //         rowLine.push(candidate.phone ? `${candidate.phone}` : '');
        //         rowLine.push(candidate.address || '');
        //         rowLine.push(candidate.city.name || '');
        //         rowLine.push((candidate.source && candidate.source.name) || '');
        //         rowLine.push(candidate.isPassed ? 'Đạt' : 'Không đạt');
        //         rowLine.push(candidate.adminNote || '');

        //         result.push(rowLine);
        //     });
        //     return result;
        // },

        // async handleClickExport(filterValue) {
        //     await this.fetchDataExport(filterValue);
        //     const date = moment().format('YYYYMMDD');
        //     const fileName = `data_${date}.csv`;
        //     const headerLine = HEADER_CSV.join(',');
        //     const data = this.formatDataExport();
        //     const rowsLine = data.map((row) => row.join(',')).join('\n');
        //     const csv = `${headerLine}\n`.concat(rowsLine);
        //     ExcelHelper.downloadCSVFile(fileName, csv);
        //     this.isLoading = false;
        // },

        async handleChangeAssigneeSalon({ id, salonAssignee }) {
            const params = {
                id,
                salonAssignee,
            };
            this.isLoading = true;
            try {
                const response = await candidateRepository.editCandidate(
                    params
                );
                if (response.data && response.data.meta.success) {
                    this.$success({
                        title: 'Map ứng viên đến salon thành công',
                        okText: 'Tiếp tục',
                        centered: true,
                        onOk: () => {
                            this.fetchData();
                        },
                    });
                } else {
                    this.$error({
                        title: 'Map ứng viên thất bại!',
                        okText: 'OK',
                        centered: true,
                        onOk: () => {},
                    });
                }
            } catch (error) {
                this.$error({
                    title: 'Map ứng viên thất bại!',
                    okText: 'OK',
                    centered: true,
                    onOk: () => {},
                });
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    min-height: calc(100vh - 120px);
    background-color: #fff;
    margin: 15px 25px;
    padding: 15px;

    .total-card {
        background: #fbfbfb;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        padding: 15px;
        .total {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .title {
                color: rgba(0, 0, 0, 0.85);
                font-size: 14px;
                line-height: 22px;
            }

            .result {
                color: rgba(0, 0, 0, 0.85);
                font-size: 24px;
                line-height: 32px;
            }
        }
        .total-border {
            border-left: 0.5px solid #ccc;
            border-right: 0.5px solid #ccc;
        }
    }
}
</style>
